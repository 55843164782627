//右键菜单项
export class MenuItem{
    menuItemId:string//右键菜单项id
    menuItemName:string//右键菜单项名称
    methodName:string////右键菜单项被单击的时候要执行的方法名称
    method:any//配合methodName用，将会在右键菜单被单击的时候调用（ContextMenuCreator的create方法那里指定调用的）

    constructor(menuItemId:string,menuItemName:string,methodName:string) {
        this.menuItemId=menuItemId;
        this.menuItemName=menuItemName;
        this.methodName=methodName;
        if (this.methodName == null) this.methodName = '';
        else {
            if (this.methodName.indexOf("(") < 0) this.methodName += "('" + this.menuItemId + "')";
            this.method =()=>{
                eval(this.methodName);
            };
        }
    }
}
export class ContextMenuCreator{//右键菜单，里面会包含一个或多个菜单项
    public creator_var_name:string;//右键菜单name前缀
    public creator_var_id:string;//右键菜单id前缀
    public menu_width:number;//右键菜单宽
    public menu_height:number;//右键菜单高
    public menu_items:Array<MenuItem>;
    public is_pop:boolean=false;//当前右键菜单是否处于显示状态
    public m_div:any;//右键菜单放到table里面的，table放到div里面的，div放到body上的（动态创建出来的）
    public m_table:any;//右键菜单放到table里面的（动态创建出来的）
    public mouseX?:number;//创建右键菜单的位置，就是xy坐标(鼠标点击的位置)
    public mouseY?:number;//创建右键菜单的位置，就是xy坐标(鼠标点击的位置)
    public node:any;//创建右键菜单的dom节点

    constructor(creator_var_name:any, creator_var_id:any, menu_width:any, menu_height:any) {
        this.creator_var_name=creator_var_name;
        this.creator_var_id=creator_var_id;
        this.menu_width=menu_width;
        this.menu_height=menu_height;
        this.menu_items = [];
    }
    //为右键菜单对象添加右键菜单项
    public addItem(menuItem:MenuItem):void{
        this.menu_items.push(menuItem);
    }
    //关闭右键菜单
    public close():void{
        document.oncontextmenu=()=>{return true;}
        document.body.removeChild(this.m_div);
        this.is_pop = false;
    }
    //创建一个div放到body上，用于存放右键菜单内容
    public createBottomBoard(menuX:number, menuY:number):void{
        if(this.menu_width < 0) this.menu_width = 150;//设置右键菜单默认宽
        if(this.menu_height < 0) this.menu_height = 100;//设置右键菜单默认高
        //建立一个层(底层)
        this.m_div = document.createElement("div");
        this.m_div.id = this.creator_var_id + "_div";
        this.m_div.style.position = "absolute";
        this.m_div.style.left = menuX + "px";
        this.m_div.style.top = menuY + "px";
        this.m_div.style.width = this.menu_width + "px";
        this.m_div.style.height = this.menu_height + "px";
        this.m_div.style.borderWidth = "1px";
        this.m_div.style.zIndex = "9910";
        this.m_div.style.display = "block";
        document.body.appendChild(this.m_div);
    }
    //创建右键菜单（menuX、menuY分别表示坐标xy，就是鼠标点击的位置；node是被鼠标右键点击的dom节点，这个节点即将被下方方法创建出右键菜单）  这个方法肯定是某个地方点击右键的时候调用
    public create(menuX:number, menuY:number, node:any):void{
        document.oncontextmenu=()=>{return   false;}
        this.createBottomBoard(menuX, menuY);
        this.node = node;
        this.mouseX = menuX;
        this.mouseY = menuY;
        //建立一个table到上面创建的div上，然后在table中创建右键菜单项
        this.m_table = document.createElement("table");
        this.m_table.id = this.creator_var_id + "_table";
        this.m_table.style.fontSize = "11px";
        this.m_table.style.width = "100%";
        this.m_table.style.height = "100%";
        this.m_table.style.backgroundColor = "#F9F8F7";
        this.m_table.style.borderStyle = "solid";
        this.m_table.style.cellPadding = "0";
        this.m_table.style.cellSpacing = "0";
        this.m_table.style.borderWidth = "1px";
        this.m_table.style.borderColor = "#666666";
        this.m_div.appendChild(this.m_table);

        let m_tr, m_td;
        for(let i = 0; i < this.menu_items.length; i++) {
            m_tr = this.m_table.insertRow(i);//为table创建一行
            m_tr.style.borderWidth = "0";
            m_tr.style.fontSize = "12px";

            m_td = m_tr.insertCell(0);//为行创建第一列
            m_td.style.backgroundColor = "#FFFFFF";
            m_td.style.width = "10%";

            m_td = m_tr.insertCell(1);//为行创建第二列
            m_td.style.width = "92%";
            m_td.style.paddingLeft = "5px";

            m_td.innerHTML = this.menu_items[i].menuItemName;//右键菜单项显示的内容
            if(this.menu_items[i].methodName)//右键菜单可能只是显示出来，但是没有指定方法，这个时候就不应该为他添加单击事件
            m_td.onclick = this.menu_items[i].method;//右键菜单项点击事件
            m_td.style.border = "1px solid #F9F8F7";
            m_td.style.cursor = "pointer";
            //鼠标划过右键菜单项的时候，改变对象项的背景颜色
            m_td.onmouseover = function() {this.style.backgroundColor = "#B6BDD2";this.style.border = "1px solid #0A246A"};
            //鼠标划出右键菜单项的时候，恢复对象项的背景颜色
            m_td.onmouseout = function() {this.style.backgroundColor = "#F9F8F7";this.style.border = "1px solid #F9F8F7";};
        }
        this.is_pop = true;//把右键菜单是否显示标志改为显示
    }
}