// @ts-ignore
import Raphael from 'raphael';
import FlowDesignVar from "@/views/sysviews/flow/define/ts/flowDesign/flowDesignVar";
export default class RaphaelDragUtil {

    //拖动节点开始时的事件
    public static start(node:any):void{
        if(node.parentNode)node=node.parentNode;
        node.ox = node.attr("x");
        node.oy = node.attr("y");
        //拖动的时候，把text节点变为透明和其它节点有所区分，拖动结束的时候会恢复,不能设置到父节点，会没有效果的，因为父节点是image
        if(node.childNode)node.childNode.animate({ "fill-opacity": .2}, 500);
    }
    //拖动事件
    public static move(dx:any, dy:any,node:any):void{
        if(node.parentNode)node=node.parentNode;
        let att= { x: node.ox + dx, y: node.oy + dy };
        if(att.x<0)att.x=0;//防止出上边界和左边界
        if(att.y<0)att.y=0;
        if(att.x>(FlowDesignVar.r.width-30))att.x=(FlowDesignVar.r.width-30-20);//防止出下边界和右边界
        if(att.y>(FlowDesignVar.r.height-50))att.y=(FlowDesignVar.r.height-30-20);

        node.attr(att);//重新定位拖动的节点位置
        att={x: node.ox + dx+30, y: node.oy + dy+30};
        if(att.x<30)att.x=30;//防止出上边界和左边界
        if(att.y<30)att.y=30;
        if(att.x>(FlowDesignVar.r.width-50))att.x=(FlowDesignVar.r.width-25);//防止出下边界和右边界
        if(att.y>(FlowDesignVar.r.height-30))att.y=(FlowDesignVar.r.height-15);
        if(node.childNode)node.childNode.attr(att);//重新定位拖动节点上面文字节点的位置
        //重新连线各个节点
        for (let i = FlowDesignVar.connectNodes.length; i--; ) {
            FlowDesignVar.r.drawConnect(FlowDesignVar.connectNodes[i]);
        }
    }
    //拖动结束后的事件
    public static end(node:any):void{
        if(node.parentNode)node=node.parentNode;
        if(node.childNode)node.childNode.animate({ "fill-opacity": 1 }, 500);//把节点变为不透明（因为开始拖动的时候改变了透明度的）
    }

    //------------------------连接两个点
    //获取开始和结束点的坐标
    public static getStartEnd(obj1:any, obj2:any):any{
        if(!obj1 || !obj2)return;
        let bb1 = obj1.getBBox(),
            bb2 = obj2.getBBox();
        let p = [
            { x: bb1.x + bb1.width / 2, y: bb1.y - 1 },
            { x: bb1.x + bb1.width / 2, y: bb1.y + bb1.height + 1 },
            { x: bb1.x - 1, y: bb1.y + bb1.height / 2 },
            { x: bb1.x + bb1.width + 1, y: bb1.y + bb1.height / 2 },
            { x: bb2.x + bb2.width / 2, y: bb2.y - 1 },
            { x: bb2.x + bb2.width / 2, y: bb2.y + bb2.height + 1 },
            { x: bb2.x - 1, y: bb2.y + bb2.height / 2 },
            { x: bb2.x + bb2.width + 1, y: bb2.y + bb2.height / 2 }
        ];
        let d = {}, dis = [],res=[];
        for (let i = 0; i < 4; i++) {
            for (let j = 4; j < 8; j++) {
                let dx = Math.abs(p[i].x - p[j].x),
                    dy = Math.abs(p[i].y - p[j].y);
                if (
                    (i == j - 4) ||
                    (((i != 3 && j != 6) || p[i].x < p[j].x) &&
                        ((i != 2 && j != 7) || p[i].x > p[j].x) &&
                        ((i != 0 && j != 5) || p[i].y > p[j].y) &&
                        ((i != 1 && j != 4) || p[i].y < p[j].y))
                ) {
                    dis.push(dx + dy);
                    // @ts-ignore
                    d[dis[dis.length - 1]] = [i, j];
                }
            }
        }
        if (dis.length == 0) {
            res = [0, 4];
        } else {
            // @ts-ignore
            res = d[Math.min.apply(Math, dis)];
        }
        let result = {} as any;
        result.start = {};
        result.end = {};
        result.start.x = p[res[0]].x;
        result.start.y = p[res[0]].y;
        result.end.x = p[res[1]].x;
        result.end.y = p[res[1]].y;
        return result;
    }
    //画两个点之间的连线（包括终点的三角箭头）
    public static getArr(x1:any, y1:any, x2:any, y2:any, size:any,type:number=0):any{
        let angle = Raphael.angle(x1, y1, x2, y2);//得到两点之间的角度
        let a45 = Raphael.rad(angle - 45);//角度转换成弧度
        let a45m = Raphael.rad(angle + 45);
        let x2a = x2 + Math.cos(a45) * size;
        let y2a = y2 + Math.sin(a45) * size;
        let x2b = x2 + Math.cos(a45m) * size;
        let y2b = y2 + Math.sin(a45m) * size;
        if(type==0)return ["M", x1, y1, "L", x2, y2, "L", x2a, y2a, "M", x2, y2, "L", x2b, y2b];//要箭头
        else if(type==1)return ["M", x1, y1, "L", x2, y2];//不要箭头
    }
}